import {Form, Formik} from 'formik'
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import ButtonAuthCancel from '../../../../_metronic/layout/components/buttons/ButtonAuthCancel'
import ButtonAuthSubmit from '../../../../_metronic/layout/components/buttons/ButtonAuthSubmit'
import {TextInput} from '../../../modules/Form'
import {executeLoginInitialValues, executeLoginValidation} from '../validations/usecases/ExecuteLoginValidation'
import {login} from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'
import { HeaderTextWidget } from '../widgets/HeaderTextWidget'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link } from 'react-router-dom'

export function Login() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [inputType, setInputType] = useState("password");
    const [eye, setEye] = useState("bi bi-eye-slash-fill");

    function toggleEye(e: EventTarget, eye: string) {
        if(eye === "bi bi-eye-fill"){
            setEye("bi bi-eye-slash-fill");
            setInputType("password")
            return;
        }
        else if(eye === "bi bi-eye-slash-fill"){
            setEye("bi bi-eye-fill");
            setInputType("text")
            return
        }
    }

    async function handleSubmit(values: typeof executeLoginInitialValues) {
        try {
            setLoading(true)
            /*Realizo a autenticação*/
            const { data } = await login(values)
            
            /*Recupero entre todas as companias, a principal, se nao houver ainda, o primeiro registro*/
            const companyId = data.data.user.user_companies.find(
                element => element.fl_principal === true
            )?.company.id ?? data.data.user.user_companies.at(0)?.company.id;
            
            dispatch(
                auth.actions.login(
                    data.data.token.access_token, 
                    data.data.token.refresh_token,
                    companyId ?? 0
                )
            )
        } finally {
            setLoading(false)
        }
    }
    return (
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 mx-auto">
            <div className='text-center mb-5'>
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/auth/logo-auth.png')}
                    className='h-45px'
                />
            </div>
            <Formik
                initialValues={executeLoginInitialValues}
                validationSchema={executeLoginValidation}
                onSubmit={handleSubmit}
            >
                <Form className='form w-100' noValidate id='kt_login_signin_form'>
                    <HeaderTextWidget>
                        Bem-vindo(a) a RubyY Empresas!
                        <br />
                        O plano é não ter plano.
                    </HeaderTextWidget>

                    <div className='fv-row mb-5'>
                        <TextInput
                            name='email'
                            placeholder='Entre com o e-mail'
                            label='E-mail'
                            type='email'
                        />
                    </div>

                    <div className='fv-row mb-5'>
                        <TextInput
                            name='password'
                            placeholder='Entre com a senha'
                            label='Senha'
                            type={inputType}
                            eye={eye}
                            toggleEye={toggleEye}
                        />
                        <div className='text-end mt-2'>
                            <Link to={'/autenticacao/esqueceu-senha'}>
                                <div className='form-label fs-6 fw-bolder text-gray-800 text-hover-primary'>
                                    Esqueceu a senha?
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className='text-center'>
                        <ButtonAuthSubmit loading={loading} showMargin={false}></ButtonAuthSubmit>
                        <div className='my-5'>
                            <span className='fw-bolder'>Quer fazer parte? Faça o cadastro da empresa!</span>
                        </div>
                        <ButtonAuthCancel 
                            buttonTitle='Cadastrar sua empresa'
                            buttonUrl={'/autenticacao/registrar'}
                        />
                    </div>
                </Form>
            </Formik>
        </div>
    )
}
