import { NotificationMessageModel } from '../../../../app/shared/models'
import NotificationWidget from './NotificationWidget'


interface Props {
  notifications: NotificationMessageModel[]
}

export function HeaderNotificationsMenu({notifications}: Props) {
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px bg-white'
      data-kt-menu='true'
    >
      <div className='d-flex flex-column bgi-no-repeat rounded-top bg-primary'>
        <h3 className='text-white fw-bold px-9 my-10'>
          Notificações{' '}
          <span className='fs-8 opacity-75 ps-3'>
            {notifications.filter((item) => !item.read_at).length} notificações
          </span>
        </h3>
      </div>

      <div className='scroll-y mh-325px my-3 px-2'>
        {notifications?.length !== 0 ? (
          notifications?.map((notification, index) => (
            <NotificationWidget
              created_at={notification.created_at}
              title={notification.data.title}
              message={notification.data.message}
              color={notification.color}
              icon={notification.color}
              key={`not-${index}`}
            />
          ))
        ) : (
          <p className='text-center my-5'>
            Nenhuma notificação disponível! <br /> Você não possui notificações.
          </p>
        )}
      </div>
    </div>
  )
}
