import axios from 'axios'
import {Formik} from 'formik'
import {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {toast} from 'react-toastify'
import { validationSchema, ValuesStoreUpdateCompany } from '../models'
import { CompanyModel } from '../models/GetCompanyByIdModel'
import { ButtonSubmit, TextInput, TextInputMask } from '../../Form'
import { cellphoneMask, CNPJMask } from '../../masks'

interface Props {
    onClose: (isOpen: boolean) => void
    company: CompanyModel
    onUpdate: () => void
    isOpen: boolean
}

export default function EditInfoCompanyModal({onClose, isOpen, company, onUpdate}: Props) {

    const initialValuesCompanyEdit: ValuesStoreUpdateCompany = {
        nm_company: company.nm_company,
        nm_fantasy: company.nm_fantasy ?? '',
        cd_phone_number: company.cd_phone_number,
        cd_cnpj: company.cd_cnpj,
        ds_contact_email: company.ds_contact_email,
        url_person: company.url_person,
    }

    const [loading, setLoading] = useState(false)
    
    async function handleEdit(values: ValuesStoreUpdateCompany) {
        try {
            setLoading(true)
            await axios.put(`/company/update`, values)
            toast.success('Empresa atualizada com sucesso!')
            onUpdate()
            onClose(false)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            onHide={() => onClose(false)}
            show={isOpen}
            size='xl'
            aria-labelledby='contained-modal-title-center'
            centered
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-center'>Atualizar informações da empresa</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Formik
                    initialValues={initialValuesCompanyEdit}
                    validationSchema={validationSchema}
                    onSubmit={(values: ValuesStoreUpdateCompany) => {
                        handleEdit(values)
                    }}
                >
                    {(props) => {
                        const {handleSubmit} = props
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <div className='row'>
                                    <div className='col-lg-6 col-12 mb-5'>
                                        <TextInput
                                            name='nm_company'
                                            label='Razão Social'
                                            placeholder='Entre com a razão social'
                                            required
                                        />
                                    </div>
                                    <div className='col-lg-6 col-12 mb-5'>
                                        <TextInput
                                            name='nm_fantasy'
                                            label='Nome Fantasia'
                                            placeholder='Entre com o nome fantasia'
                                            required
                                        />
                                    </div>
                                    <div className='col-lg-4 col-12 mb-5'>
                                        <TextInputMask
                                            name='cd_phone_number'
                                            label='Telefone'
                                            placeholder='Entre com o telefone'
                                            mask={cellphoneMask}
                                            required
                                        />
                                    </div>
                                    <div className='col-lg-4 col-12 mb-5'>
                                        <TextInputMask
                                            name='cd_cnpj'
                                            label='CNPJ'
                                            placeholder='Entre com o CNPJ'
                                            mask={CNPJMask}
                                            required
                                        />
                                    </div>
                                    <div className='col-lg-4 col-12 mb-5'>
                                        <TextInput
                                            name='ds_contact_email'
                                            label='E-mail de contato'
                                            required
                                            placeholder='Entre com o e-mail'
                                        />
                                    </div>
                                </div>
                                <ButtonSubmit
                                    loading={loading}
                                    label={'Atualizar'}
                                    onCancel={() => onClose(false)}
                                /> 
                            </form>
                        )
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}
