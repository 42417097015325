import {lazy} from 'react'
import { shallowEqual, useSelector } from 'react-redux';
import {Route, Switch} from 'react-router-dom'
import { RootState } from '../../../setup';
import { Container } from '../../modules/components';
import { Error403Forbidden } from '../../pages/errors/Error403Forbidden';
import { PermissionPagesEnum } from '../../shared/enums';

export default function FinancialRouter() {
    const permissionPages: number[] = useSelector<RootState>(
        ({auth}) => auth.permission_pages, shallowEqual
    ) as number[]
    
    /*Rotas para Pedidos*/
    const DatatableOrders = lazy(() => import('../../pages/financial/orders/DatatableOrders'));
    const ShowOrder = lazy(() => import('../../pages/financial/orders/ShowOrder'));

    /*Rotas para Cartões*/
    const DatatableCards = lazy(() => import('../../pages/financial/cards/DatatableCards'));

    return (
        <Container loading={permissionPages === undefined}>
            <Switch>
                <Route 
                    path='/financeiro/pedidos/:id' 
                    component={permissionPages?.includes(PermissionPagesEnum.FinancialOrders) ? ShowOrder : Error403Forbidden} 
                />
                <Route 
                    path='/financeiro/pedidos' 
                    component={permissionPages?.includes(PermissionPagesEnum.FinancialOrders) ? DatatableOrders : Error403Forbidden} 
                />
                <Route 
                    path='/financeiro/cartoes' 
                    component={permissionPages?.includes(PermissionPagesEnum.FinancialCards) ? DatatableCards : Error403Forbidden} 
                />
            </Switch>
        </Container>
    )
}
