import {Field, FieldProps, FormikProps} from 'formik'
import React from 'react'
import {parseToNull} from '../utils/parseToNull'

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
    name: string
    label?: string
    value?: string
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void
    required?: boolean
    disabled?: boolean
    placeholder?: string
    formik?: FormikProps<any>
    children: React.ReactNode
}
export function Select({
    name,
    label,
    value = '',
    onChange,
    onBlur,
    required = false,
    children,
    placeholder,
    formik,
    disabled = false,
    ...props
}: Props) {
    return (
        <>
            {label && (
                <label htmlFor={name} className='form-label fs-6 fw-bolder'>
                    {label}
                    {required && <span className='text-danger'>*</span>}
                </label>
            )}
            <Field name={name}>
                {({form}: FieldProps) => (
                    <>
                        <select
                            className='form-select form-select-solid form-select-lg'
                            id={name}
                            name={name}
                            value={value || form.values[name] || ''}
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                onChange?.(event) ||
                                    form.setFieldValue(name, parseToNull(event.target.value))
                            }}
                            onBlur={onBlur || form?.handleBlur}
                            {...props}
                        >
                            <option value={''}>{placeholder || 'Selecione...'}</option>
                            {children}
                        </select>
                        {form.touched[name] && form.errors[name] && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                        {form?.errors[name]}
                                    </span>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Field>
        </>
    )
}
