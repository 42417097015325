import * as Yup from 'yup'

export interface Filters {
    nm_specialty: string
    ds_specialty?: string | null
}

export const initialValuesFilters: Filters = {
    nm_specialty: '',
    ds_specialty: '',
}

export interface ValuesStoreUpdateCompany {
    nm_company: string
    nm_fantasy: string
    cd_phone_number: string
    cd_cnpj: string
    ds_contact_email: string
    url_person?: string
}

export const initialValuesStore: ValuesStoreUpdateCompany = {
    nm_company: '',
    nm_fantasy: '',
    cd_phone_number: '',
    cd_cnpj: '',
    ds_contact_email: '',
    url_person: '',
}

export const validationSchema = Yup.object().shape({
    nm_company: Yup.string().required('Campo obrigatório'),
    nm_fantasy: Yup.string().required('Campo obrigatório'),
    cd_phone_number: Yup.string().required('Campo obrigatório'),
    cd_cnpj: Yup.string().required('Campo obrigatório'),
    ds_contact_email: Yup.string().required('Campo obrigatório'),
    url_person: Yup.string().required('Campo obrigatório'),
})

export interface Specialists {
    success: boolean
    message: string
    data: Data
}

interface Data {
    datatable: Datatable
}

interface Datatable {
    draw: number
    iTotalRecords: number
    iTotalDisplayRecords: number
    iDisplayRecords: number
    data: Datum[]
}

interface Datum {
    id: number
    nm_specialty: string
    ds_specialty: string
}

export interface GetSpecialists {
    success: boolean
    message: string
    data: {
        specialty: {
            id: number
            nm_specialty: string
            ds_specialty: string
            created_at: Date
            updated_at: Date
            photo: null
        }
    }
}
