import {Form, Formik, FormikHelpers} from 'formik'
import {useEffect, useMemo, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import ButtonAuthCancel from '../../../../_metronic/layout/components/buttons/ButtonAuthCancel'
import ButtonAuthSubmit from '../../../../_metronic/layout/components/buttons/ButtonAuthSubmit'
import {TextInput} from '../../../modules/Form'
import {requestResetPassword} from '../redux/AuthCRUD'
import { HeaderTextWidget } from '../widgets/HeaderTextWidget'
import Alert from './Alert'

const initialValues = {
    password: '',
    changepassword: '',
}
interface Values {
    password: string
    changepassword: string
}
const registrationSchema = Yup.object().shape({
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    changepassword: Yup.string()
        .required('Password confirmation is required')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref('password')],
                "Password and Confirm Password didn't match"
            ),
        }),
})
function useQuery() {
    const {search} = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
}
export default function ChangePassword() {
    let query = useQuery()
    const token = query.get('token')
    const email = query.get('email')
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)

    async function handleSubmit(values: Values, {setStatus, setSubmitting}: FormikHelpers<Values>) {
        if (token && email) {
            setLoading(true)
            try {
                await requestResetPassword(token, email, values.password, values.changepassword)
                setLoading(false)
                setOpenAlert(true)
            } catch (error) {
                setLoading(false)
                setSubmitting(false)
                setStatus('Registration process has broken')
            } finally {
                setLoading(false)
            }
        } else {
            history.push('/autenticacao/entrar')
        }
    }
    useEffect(() => {
        if (!token || !email) {
            history.push('/autenticacao/entrar')
        }
    }, [token, email, history])
    return (
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 mx-auto">
            <div className='text-center mb-5'>
                <img
                    alt='Logo'
                    
                    src={toAbsoluteUrl('/media/auth/logo-auth.png')}
                    className='h-45px'
                />
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={registrationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    return (
                        <Form
                            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                            noValidate
                        >
                            <HeaderTextWidget>
                                Alterar a senha
                                <br />
                                Entre com a nova senha para acessar novamente a plataforma
                            </HeaderTextWidget>

                            {props.status && (
                                <div className='alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>
                                        {props.status}
                                    </div>
                                </div>
                            )}

                            <div className='mb-5 fv-row' data-kt-password-meter='true'>
                                <TextInput
                                    label='Nova senha'
                                    name='password'
                                    placeholder='Nova senha'
                                    type='password'
                                />
                            </div>

                            <div className='fv-row mb-5'>
                                <TextInput
                                    name='changepassword'
                                    label='Confirme sua senha'
                                    placeholder='Confirmação de nova senha'
                                    type='password'
                                />
                            </div>

                            <div className='text-center'>
                                <ButtonAuthSubmit
                                    loading={loading}
                                    showMargin={false}
                                    buttonTitle={'Alterar senha'}
                                ></ButtonAuthSubmit>
                                <div className='my-5'>
                                    <span className='fw-bolder'>Lembrou a senha e não quer alterar? Acesse a plataforma</span>
                                </div>
                                <ButtonAuthCancel 
                                    buttonTitle='Voltar'
                                    buttonUrl={'/autenticacao/entrar'}
                                />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            <Alert
                onClose={() => {
                    setOpenAlert(false)
                    history.push('/')
                }}
                title='Senha alterada com sucesso, acesse a plataforma!'
                open={openAlert}
            />
        </div>
    )
}
