import * as Yup from 'yup'

export const executeLoginValidation = Yup.object().shape({
    email: Yup.string()
        .email('Entre com um e-mail válido')
        .min(3, 'Mínimo de 3 caracteres')
        .max(50, 'Máximo de 50 caracteres')
        .required('E-mail é obrigatório'),
    password: Yup.string()
        .min(3, 'Mínimo 3 caracteres')
        .max(50, 'Máximo 50 caracteres')
        .required('Senha é obrigatório'),
})

export const executeLoginInitialValues = {
    email: '',
    password: '',
}