import { GoBackButton } from "../../widgets/buttons/GoBackButton";

export function Error404NotFound() {
    return (
        <div className='card bg-danger'>
            <div className='card-header'>
                <div className='card-title'>
                    <i className="fas fa-ban fs-1 text-white me-2"></i>
                    <h4 className='text-white'>Não encontrado e/ou sem permissão de acesso!</h4>
                </div>
                <div className='card-toolbar'>
                    <GoBackButton></GoBackButton>
                </div>
            </div>
        </div>
    )
}