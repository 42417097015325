import * as Yup from 'yup'

export const executeRegisterValidation = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    email: Yup.string()
        .email('Entre com um e-mail válido')
        .min(3, 'Mínimo de 3 caracteres')
        .max(50, 'Máximo de 50 caracteres')
        .required('E-mail é obrigatório'),
    cd_cpf: Yup.string().required('Campo obrigatório'),
    nm_company: Yup.string().required('Campo obrigatório'),
    nm_fantasy: Yup.string().required('Campo obrigatório'),
    cd_phone_number: Yup.string().required('Campo obrigatório'),
    cd_cnpj: Yup.string().required('Campo obrigatório'),
    ds_contact_email: Yup.string()
        .email('Entre com um e-mail válido')
        .min(3, 'Mínimo de 3 caracteres')
        .max(50, 'Máximo de 50 caracteres')
        .required('E-mail é obrigatório'),
    ds_path_social: Yup.string().required('Campo obrigatório'),
    url_person: Yup.string().required('Campo obrigatório'),
    accepted: Yup.boolean().oneOf([true], 'Você precisa aceitar nossos termos de uso e política de privacidade antes de se cadastrar.').required('Campo obrigatório!'),
})

export const executeRegisterInitialValues = {
    name: '',
    email: '',
    cd_cpf: '',
    nm_company: '',
    nm_fantasy: '',
    cd_phone_number: '',
    cd_cnpj: '',
    ds_contact_email: '',
    ds_path_social: '',
    url_person: '',
    accepted: false,
    last_accepted: new Date(),
}