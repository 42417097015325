import axios from 'axios'
import * as auth from '../../../../app/pages/auth/redux/AuthRedux'
import {useCallback, useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {Menu} from './models/Menu'

export function AsideMenuMain() {
    const dispatch = useDispatch()
    const [dataMenu, setDataMenu] = useState<Menu>()

    const getMenu = useCallback(async () => {
        const {data} = await axios.get<Menu>('/structure/get-routes')
        setDataMenu(data)
        dispatch(auth.actions.permissionPages(data.data.permissions))
    }, [dispatch])

    function Line({title}: {title?: string}) {
        return (
            <div className='menu-item'>
                <div className='menu-content py-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                        {title}
                    </span>
                </div>
            </div>
        )
    }

    useEffect(() => {
        getMenu()
    }, [getMenu])

    return (
        <>
            {dataMenu?.data.pages.map((item, index) => (
                <div key={'divMenu.' + item.id}>
                    <Line title={item.section} />
                    {item.routes.map((route, indexRouter) =>
                        !route.root ? (
                            <AsideMenuItemWithSub
                                key={route.id + '.sub'}
                                to={route.page}
                                title={route.title}
                                fontIcon='bi-archive'
                                icon={route.icon}
                            >
                                {route.submenu?.map((subItem, subIndex) => (
                                    <AsideMenuItem
                                        key={indexRouter + '.' + subIndex}
                                        to={subItem.page}
                                        title={subItem.title}
                                        hasBullet={true}
                                    />
                                ))}
                            </AsideMenuItemWithSub>
                        ) : (
                            <AsideMenuItem
                                key={route.id + '.item'}
                                to={route.page}
                                icon={route.icon}
                                title={route.title}
                                fontIcon='bi-app-indicator'
                            />
                        )
                    )}
                </div>
            ))}
        </>
    )
}
