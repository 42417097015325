import {lazy} from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {Route, Switch} from 'react-router-dom'
import { RootState } from '../../../setup'
import { Container } from '../../modules/components'
import { Error403Forbidden } from '../../pages/errors/Error403Forbidden'
import { PermissionPagesEnum } from '../../shared/enums'

export function SecurityRouter() {
    const permissionPages: number[] = useSelector<RootState>(
        ({auth}) => auth.permission_pages, shallowEqual
    ) as number[]

    /*Rotas para Historico de Atividades*/
    const DatatableAudit = lazy(() => import('../../pages/security/audit/DatatableAudit'))

    return (
        <Container loading={permissionPages === undefined}>
            <Switch>
                {/*Rotas para Historico de Atividades*/}
                <Route 
                    path='/seguranca/auditoria' 
                    component={permissionPages?.includes(PermissionPagesEnum.SecurityAudit) ? DatatableAudit : Error403Forbidden} 
                />
            </Switch>
        </Container>
    )
}
