import React, { useCallback, useEffect, useState } from 'react'
import { CardHeader, Container } from '../components'
import { CompanyModel, GetCompanyByIdModel } from './models/GetCompanyByIdModel'
import { Error404NotFound } from '../../pages/errors/Error404NotFound'
import axios from 'axios'
import EditInfoCompanyModal from './modals/EditInfoCompanyModal'

const CompanyPage: React.FC = () => {
    const [loading, setLoading] = useState(true)
    const [company, setCompany] = useState<CompanyModel>()
    const [isOpenModal, setIsOpenModal] = useState(false)

    const getData = useCallback(async () => {
        try {
            const {data} = await axios.get<GetCompanyByIdModel>(`/company/show`)
            setCompany(data.data.company)
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        getData()
    }, [getData])
    
    return (
        <div className='row'>
            <Container loading={loading}>
                { company !== undefined 
                    ?   <div className='card'>
                            <CardHeader title='Informações da empresa' goBack={false}>
                                <button
                                    onClick={() => {setIsOpenModal(true)}}
                                    className='btn btn-sm btn-light-info me-2'
                                >
                                    <i className='fa fa-edit'></i>
                                    Editar dados
                                </button>
                            </CardHeader>
                            <div className='card-body'>
                                <div className='row mb-5'>
                                    <div className='col-12'>
                                        <label>Razão Social</label>
                                        <h3>{company.nm_company}</h3>
                                    </div>
                                </div>
                                <div className='row mb-5'>
                                    <div className='col-6'>
                                        <label>Nome Fantasia</label>
                                        <h3>{company.nm_fantasy}</h3>
                                    </div>
                                    <div className='col-6'>
                                        <label>Telefone</label>
                                        <h3>{company.cd_phone_number}</h3>
                                    </div>
                                </div>
                                <div className='row mb-5'>
                                    <div className='col-5'>
                                        <label>CNPJ</label>
                                        <h3>{company.cd_cnpj}</h3>
                                    </div>
                                    <div className='col-5'>
                                        <label>E-mail de contato</label>
                                        <h3>{company.ds_contact_email ?? 'Não informado'}</h3>
                                    </div>
                                    <div className='col-2'>
                                        <label>Status</label>
                                        {company.status
                                            ?   <h3 className="text-success">Ativo</h3>
                                            :   <h3 className="text-danger">Inativo</h3>
                                        }
                                    </div> 
                                    
                                    
                                </div>
                                <div className='row'> 
                                    {company.ds_path_social && (
                                        
                                        <div className='col-6'>
                                            <label>Contrato Social</label>
                                            <h3>
                                                <a target='_blank' rel='noreferrer' href={company.ds_path_social}>Visualizar contrato social</a>
                                            </h3>
                                        </div>
                                    
                                    )}
                                    {company.url_person && (
                                        <div className='col-6'>
                                            <label>Identidade do sócio e/ou procurador representante</label>
                                            <h3>
                                                <a target='_blank' rel='noreferrer' href={company.url_person}>Visualizar Documento</a>
                                            </h3>
                                        </div>
                                    )}
                                </div>
                            
                            </div>
                        </div>
                    :   <Error404NotFound />
                }
            </Container>
            { company !== undefined &&
                <EditInfoCompanyModal
                    isOpen={isOpenModal}
                    onClose={setIsOpenModal}
                    onUpdate={() => getData()}
                    company={company}
                /> 
            }
        </div>
    )
}

export default CompanyPage
