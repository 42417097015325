export enum OrderStatusEnum {
    Opened = 1,
    PendingPayment = 2,
    PendingProof = 3,
    PendingApprove = 4,
    PaymentRejected = 5,
    ManualApproved = 6,
    Approved = 7,
    Finished = 8,
    Canceled = 9,
}