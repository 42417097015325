import axios from 'axios'
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Tooltip } from '../../../../app/modules/components'
import { NotificationMessageModel, UserModel } from '../../../../app/shared/models'
import { RootState } from '../../../../setup'
import {HeaderUserMenu} from '../../../partials'
import { HeaderNotificationsMenu } from '../../../partials/layout/header-menus/HeaderNotificationsMenu'
import { HeaderSelectCompany } from '../../../partials/layout/header-menus/HeaderSelectCompany'
import Pusher from 'pusher-js'
import Swal from 'sweetalert2'
import { toAbsoluteUrl } from '../../../helpers'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {

    const [notifications, setNotifications] = useState<NotificationMessageModel[]>([])
    const [someNotRead, setSomeNotRead] = useState(false)
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    const companyId: number = useSelector<RootState>(
        ({auth}) => auth.company_id,
        shallowEqual
    ) as number

    async function getNotification() {
        const {data} = await axios.get('/profile/notifications/index')
        setNotifications(data.data.notifications)
        setSomeNotRead(data.data?.notifications?.some((item: NotificationMessageModel) => !item.read_at))
    }

    useEffect(() => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY || '', {
            cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER || 'sa1',
            forceTLS: false,
            authEndpoint: process.env.REACT_APP_PUSHER_APP_ENDPOINT,
            authTransport: 'ajax',
            auth: {
                params: {
                    email: user.email,
                },
            },
        })

        const channel = pusher.subscribe(
            `private-App.Models.User.${user.id}`
        )
        
        channel.bind(process.env.REACT_APP_PUSHER_EVENT_NAME || '', function (data: any) {
            console.log(data.data)
            Swal.fire({
                icon: data.icon || 'success',
                title: data.title,
                html: data.description,
                confirmButtonText: 'Recebido!',
                allowOutsideClick: false,
            }).then((result) => {
                console.log(result)
                if (result['isConfirmed']){
                    document.location.reload()
                }
            })
            var audio = new Audio(toAbsoluteUrl('/media/sounds/alert.mp3'))
            audio.play()
            getNotification()
        })

        return () => {
            pusher.unsubscribe(`private.App.Models.User.${user.id}`)
        }
    }, [user])

    useEffect(() => {
        getNotification()
    }, [])

    return (
        <div className='d-flex align-items-stretch flex-shrink-0'>
            { user?.user_companies.length <= 1 
                ?   <div
                        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                        id='kt_header_clinic_menu_toggle'
                    >
                        <div
                            className={clsx(`w-100 d-flex align-items-center'`)}
                            data-kt-menu-trigger='click'
                            data-kt-menu-attach='parent'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='bottom'
                        >
                            <h5 className='text-light px-2 m-0'>
                                {user?.user_companies.find((element) => element.company.id === companyId)?.company.nm_fantasy}
                            </h5>
                        </div>
                    </div>
                :   <div
                        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                        id='kt_header_clinic_menu_toggle'
                    >
                        <div
                            className={clsx(`cursor-pointer w-100 d-flex align-items-center'`)}
                            data-kt-menu-trigger='click'
                            data-kt-menu-attach='parent'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='bottom'
                        >
                            <h5 className='text-light px-2 m-0'>
                                {user?.user_companies.find((element) => element.company.id === companyId)?.company.nm_fantasy}
                            </h5>
                            <i className='text-white fas fa-caret-down' />
                        </div>
                        <HeaderSelectCompany />
                    </div>
            }
            <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                <Tooltip text="Notificações" placement="bottom">
                    <div
                    className={'btn btn-icon btn-active-dark position-relative'}
                    data-kt-menu-trigger='click'
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='bottom'
                    >
                    <i className='text-light fas fa-bell'></i>
                    {someNotRead && (
                        <>
                        <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
                        <span className='pulse-ring'></span>
                        </>
                    )}
                    </div>
                </Tooltip>
                <HeaderNotificationsMenu notifications={notifications} />
            </div>
            <div
                className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                id='kt_header_user_menu_toggle'
            >
                <Tooltip text="Configurações de perfil" placement="bottom">
                    <div
                        className={clsx(
                            'cursor-pointer symbol btn btn-icon btn-active-dark',
                            toolbarUserAvatarHeightClass
                        )}
                        data-kt-menu-trigger='click'
                        data-kt-menu-attach='parent'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='bottom'
                    >
                        <i className={'text-light fas fa-user-circle'}></i>
                    </div>
                </Tooltip>
                
                <HeaderUserMenu />
            </div>
        </div>
    )
}

export {Topbar}
