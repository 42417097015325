export function Error403Forbidden() {
    return (
        <div className='card bg-danger'>
            <div className='card-header'>
                <div className='card-title'>
                    <i className="fas fa-ban fs-1 text-white me-2"></i>
                    <h4 className='text-white'>Você não possui permissão de acesso para acessar essá página!</h4>
                </div>
            </div>
        </div>
    )
}