import { useHistory } from 'react-router-dom'

export function GoBackButton() {
    const history = useHistory()

    return (
        <button onClick={() => history.goBack()} className='btn btn-sm btn-secondary'>
            <i className='fas fa-arrow-alt-circle-left'></i>
            Voltar
        </button>
    )
}
