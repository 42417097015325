import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import { UserModel } from '../../../../app/shared/models'
import {RootState} from '../../../../setup'

const HeaderUserMenu: FC = () => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-2 fs-6 w-275px'
            data-kt-menu='true'
        >
            <div className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-0'>
                    <div className='symbol symbol-50px me-2'>
                        <span className='symbol symbol-35 symbol-light-success'>
                            <span className='symbol-label font-size-h5 font-weight-bold'>
                                {user?.name.split('')[0]}
                            </span>
                        </span>
                    </div>

                    <div className='d-inline-block flex-column text-truncate'>
                        <div className='fw-bolder d-flex align-items-center fs-5'>
                            {user?.name.split(' ').at(0)} {user?.name.split(' ').at(-1)}
                        </div>
                        {user?.email}
                    </div>
                </div>
            </div>

            <div className='separator my-2'></div>

            <div className='menu-item px-5'>
                <Link to={'/empresa'} className='menu-link px-5'>
                    Visualizar empresa
                </Link>
            </div>
            <div className='separator my-2' />
            <div className='menu-item px-5'>
                <Link to={'/meu-perfil'} className='menu-link px-5'>
                    Meu perfil
                </Link>
            </div>
            <div className='separator my-2' />
                <div className='menu-item px-5'>
                    <Link to={'/termos-e-condicoes'} className='menu-link px-5'>
                        Termos e Condições
                    </Link>
                </div>
            <div className='separator my-2' />
            <div className='menu-item px-5'>
                <Link to='/logout' className='menu-link px-5'>
                    Sair
                </Link>
            </div>
        </div>
    )
}

export {HeaderUserMenu}
