import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import { UserModel } from '../../../shared/models'
import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    Login: '[Login] Action',
    Logout: '[Logout] Action',
    UserRequested: '[Request User] Action',
    UserLoaded: '[Load User] Auth API',
    SetUser: '[Set User] Action',
    refresh_token: '[Refresh Token] Action',
    setCompany: '[Set Company] Action',
    clearToken: "[Clear Token] Action",
    permissionPages: "[Permission Pages] Action",
}

const initialAuthState: IAuthState = {
    user: undefined,
    token: undefined,
    refresh_token: undefined,
    company_id: undefined,
    permission_pages: undefined
}

export interface IAuthState {
    user?: UserModel
    token?: string
    refresh_token?: string
    company_id?: number
    permission_pages?: number[]
}

export const reducer = persistReducer(
    {storage, key: 'v100-demo1-auth', whitelist: ['user', 'token', 'refresh_token', 'company_id']},
    (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
        switch (action.type) {
            case actionTypes.Login: {
                const token = action.payload?.token
                const refresh_token = action.payload?.refresh_token
                const company_id = action.payload?.company_id
                return {token, user: undefined, refresh_token, company_id}
            }

            case actionTypes.Logout: {
                return initialAuthState
            }

            case actionTypes.UserRequested: {
                return {...state, user: undefined}
            }

            case actionTypes.UserLoaded: {
                const user = action.payload?.user
                return {...state, user}
            }

            case actionTypes.SetUser: {
                const user = action.payload?.user
                return {...state, user}
            }

            case actionTypes.refresh_token: {
                const token = action.payload?.token
                const refresh_token = action.payload?.refresh_token
                const company_id = action.payload?.company_id
                return {
                    token,
                    refresh_token,
                    company_id,
                    user: undefined,
                }
            }

            case actionTypes.setCompany: {
                const company_id = action.payload?.company_id
                return {...state, company_id}
            }

            case actionTypes.clearToken: {
                return {
                  token: undefined,
                  refresh_token: undefined,
                  user: undefined,
                };
            }
            
            case actionTypes.permissionPages: {
                const permission_pages = action.payload?.permission_pages
                return {...state, permission_pages}
            }

            default:
                return state
        }
    }
)

export const actions = {
    login: (token: string, refresh_token: string, company_id: number) => ({
        type: actionTypes.Login,
        payload: {token, refresh_token, company_id},
    }),
    logout: () => ({type: actionTypes.Logout}),
    requestUser: () => ({
        type: actionTypes.UserRequested,
    }),
    fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user: user}}),
    setUser: (user?: UserModel) => ({type: actionTypes.SetUser, payload: {user: user}}),
    refresh_token: (token: string, refresh_token: string, company_id: number) => ({
        type: actionTypes.refresh_token,
        payload: {token, refresh_token, company_id},
    }),
    setCompany: (company_id: number) => ({
        type: actionTypes.setCompany,
        payload: {company_id: company_id},
    }),
    clearToken: () => ({
        type: actionTypes.clearToken,
    }),
    permissionPages: (permission_pages: number[]) => ({
        type: actionTypes.permissionPages,
        payload: {permission_pages: permission_pages},
    }),
}

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser())
    })

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const {data} = yield getUserByToken()
        yield put(actions.fulfillUser(data.data.user))
    })
}
