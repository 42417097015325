import {Link} from 'react-router-dom'

interface Props {
    title: string
    url?: string
    onClick?: () => void
    icon?: string
    color?: string
}
export function ButtonNew({title, url, onClick, icon = 'fa fa-plus-circle', color = 'success'}: Props) {
    return url ? (
        <Link to={url} className={`ms-2 btn btn-sm btn-light-${color}`}>
            <i className={icon}></i>
            {title}
        </Link>
    ) : (
        <button onClick={onClick} className={`ms-2 btn btn-sm btn-light-${color}`}>
            <i className={icon}></i>
            {title}
        </button>
    )
}
