import {Form, Formik, FormikHelpers} from 'formik'
import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import ButtonAuthCancel from '../../../../_metronic/layout/components/buttons/ButtonAuthCancel'
import ButtonAuthSubmit from '../../../../_metronic/layout/components/buttons/ButtonAuthSubmit'
import {TextInput} from '../../../modules/Form'
import {requestPassword} from '../redux/AuthCRUD'
import { HeaderTextWidget } from '../widgets/HeaderTextWidget'
import Alert from './Alert'

const initialValues = {
    email: '',
}

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Entre com um e-mail válido')
        .min(3, 'Mínimo de 3 caracteres')
        .max(50, 'Máximo de 50 caracteres')
        .required('E-mail é obrigatório'),
})

export function ForgotPassword() {
    const [loading, setLoading] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const history = useHistory()

    async function handleSubmit(
        values: typeof initialValues,
        {setStatus, setSubmitting}: FormikHelpers<typeof initialValues>
    ) {
        try {
            setLoading(true)
            setHasErrors(undefined)
            await requestPassword(values.email)
            setOpenAlert(true)
        } catch (error: any) {
            setHasErrors(true)
            setSubmitting(false)
            setStatus(error?.response.data.message || 'Falha ao enviar o email')
        } finally {
            setLoading(false)
        }
    }
    return (
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 mx-auto">
            <div className='text-center mb-5'>
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/auth/logo-auth.png')}
                    className='h-45px'
                />
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={forgotPasswordSchema}
                onSubmit={(values, propsFormik) => {
                    handleSubmit(values, propsFormik)
                }}
            >
                {(props) => (
                    <Form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate>
                        <HeaderTextWidget>
                            Esqueceu a senha ?
                            <br />
                            Entre com o e-mail para realizar a alteração da senha.
                        </HeaderTextWidget>
                        {hasErrors === true && (
                            <div className='alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{props.status}</div>
                            </div>
                        )}
                        <div className='fv-row mb-10'>
                            <TextInput
                                name='email'
                                label='E-mail'
                                type='email'
                                placeholder='Entre com o e-mail'
                            />
                        </div>
                        <div className='text-center'>
                            <ButtonAuthSubmit loading={loading}></ButtonAuthSubmit>
                            <ButtonAuthCancel buttonUrl={'/autenticacao/entrar'}></ButtonAuthCancel>
                        </div>
                        <Alert
                            onClose={() => {
                                setOpenAlert(false)
                                history.push('/')
                            }}
                            title='As instruções para alterar sua senha foram enviadas para seu e-mail, por favor, verifique-o!'
                            open={openAlert}
                        />
                    </Form>
                )}
            </Formik>
        </div>
        
    )
}
