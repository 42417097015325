import {FC} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import { UserModel } from '../../../../app/shared/models'
import {RootState} from '../../../../setup'
import * as auth from '../../../../app/pages/auth/redux/AuthRedux'
import axios from 'axios'

const HeaderSelectCompany: FC = () => {
    const dispatch = useDispatch()

    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    async function handleSelectClinic(company_id: number) {
        dispatch(auth.actions.setCompany(company_id))

        /*Realizo a troca do principal*/
        axios.put(`/profile/update/${company_id}/principal`).then(
            () => document.location.reload()
        )
    }

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
            data-kt-menu='true'
        >
            <ul className='list-group px-2'>
                {user?.user_companies.map((userCompany) => userCompany.company.fl_approved && userCompany.company.status && (
                    <li key={userCompany.company.id} className='list-group-item border-0 menu-item'>
                        <button
                            className='btn btn-sm btn-active-primary w-100 text-start'
                            onClick={() => handleSelectClinic(userCompany.company.id)}
                        >
                            {userCompany.company.nm_fantasy}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export {HeaderSelectCompany}

