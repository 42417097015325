import {lazy} from 'react'
import { shallowEqual, useSelector } from 'react-redux';
import {Route, Switch} from 'react-router-dom'
import { RootState } from '../../../setup';
import { Container } from '../../modules/components';
import { Error403Forbidden } from '../../pages/errors/Error403Forbidden';
import { PermissionPagesEnum } from '../../shared/enums';

export default function ManagementsRouter() {

    const permissionPages: number[] = useSelector<RootState>(
        ({auth}) => auth.permission_pages, shallowEqual
    ) as number[]

    /*Rotas para Beneficiários*/
    const DatatableBeneficiaries = lazy(() => import('../../pages/management/beneficiaries/DatatableBeneficiaries'));
    const ShowBeneficiary = lazy(() => import('../../pages/management/beneficiaries/ShowBeneficiary'));
    
    /*Rotas para Usuários*/
    const DatatableUsers = lazy(() => import('../../pages/management/users/DatatableUsers'));
    const ShowUser = lazy(() => import('../../pages/management/users/ShowUser'));

    /*Rotas para Privilegios*/
    const DatatablePrivileges = lazy(() => import('../../pages/management/privileges/DatatablePrivileges'));
    const ShowPrivilege = lazy(() => import('../../pages/management/privileges/ShowPrivilege'));

    return (
        <Container loading={permissionPages === undefined}>
            <Switch>
                {/*Rotas para Beneficiários*/}
                <Route 
                    path='/gestao/beneficiarios/:id' 
                    component={permissionPages?.includes(PermissionPagesEnum.ManagementBeneficiaries) ? ShowBeneficiary : Error403Forbidden}
                />
                <Route 
                    path='/gestao/beneficiarios' 
                    component={permissionPages?.includes(PermissionPagesEnum.ManagementBeneficiaries) ? DatatableBeneficiaries : Error403Forbidden}
                />

                {/*Rotas para Usuários*/}
                <Route 
                    path='/gestao/usuarios/:cpf' 
                    component={permissionPages?.includes(PermissionPagesEnum.ManagementUsers) ? ShowUser : Error403Forbidden}
                /> 
                <Route 
                    path='/gestao/usuarios' 
                    component={permissionPages?.includes(PermissionPagesEnum.ManagementUsers) ? DatatableUsers : Error403Forbidden}
                />

                {/*Rotas para Perfis*/}
                <Route 
                    path='/gestao/perfis/:id' 
                    component={permissionPages?.includes(PermissionPagesEnum.ManagementPrivileges) ? ShowPrivilege : Error403Forbidden}
                />
                <Route 
                    path='/gestao/perfis' 
                    component={permissionPages?.includes(PermissionPagesEnum.ManagementPrivileges) ? DatatablePrivileges : Error403Forbidden}
                />
            </Switch>
        </Container>
    )
}
