import axios from 'axios'
import { AuthTokenModel } from '../../../shared/models/AuthTokenModel'
import { ExecuteLoginResponseModel } from '../../../shared/models/usecases/ExecuteLoginResponseModel'


export const GET_USER_BY_token_URL = `/profile/show`
export const LOGIN_URL = `/auth/login`
export const LOGOUT_URL = `/auth/logout`
export const REQUEST_PASSWORD_URL = `/auth/forgot_password`
export const RESET_PASSWORD_URL = `/auth/reset_password`
export const REFRESH_TOKEN_URL = `/auth/refresh_token`

interface LoginRequest {
    email: string
    password: string
}
export function login({email, password}: LoginRequest) {
    return axios.post<ExecuteLoginResponseModel>(LOGIN_URL, {email, password})
}

export function logout() {
    return axios.post(LOGOUT_URL)
}

export function requestPassword(email: string) {
    return axios.post<{success: boolean; message: string}>(REQUEST_PASSWORD_URL, {email})
}

export function requestResetPassword(
    token: string,
    email: string,
    password: string,
    password_confirmation: string
) {
    return axios.post(RESET_PASSWORD_URL, {token, email, password, password_confirmation})
}
export function getUserByToken() {
    return axios.get<ExecuteLoginResponseModel>(GET_USER_BY_token_URL)
}
export function refresh_token(refresh_token: string) {
    return axios.post<AuthTokenModel>(REFRESH_TOKEN_URL, {refresh_token})
}
